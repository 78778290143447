<template>
  <div id="apps">
   <div class="tytuihuo clear">
     <p class="p">退款申请，审核中</p>
     <p class="p p8">￥6.9元</p>
     <p class="p ps">温馨提示：审核通过后，系统会在1-2天内提交微信支付处理，微
信审核完成后1-3个工作日退回到你的支付账户。若超时未收到退款
请联系官方客服进行核实。</p>
  <div class="liry clear">
    <img src="../assets/timg.jpeg" class="img1"/>
    <div class="ggg">
       <p>小狗机器人</p>
       <p>订单金额：￥6.9元</p>
    </div>
    <img src="../assets/arr1.png" class="img2"/>
  </div>
  <p class="p">订单编号：11111111111111111111111111111<br/>
退款原因：多拍，错拍<br/>
申请时间：2020-12-01 09：24：50</p>
   </div>
  </div>
</template>
<script>
export default {
  name: 'app',
  components:{
  },
   data () {
      return {

      }
  },
  methods:{
  }
}
</script>
<style>
  .clear{clear:both;overflow: hidden;}
  *{margin:0;padding:0;}
  li{list-style-type: none;}
  body{background:#fff;height: 100vh;}
  .liry{width:100%;padding-top:4%;border-bottom:solid 1px rgba(187, 187, 187, 100);border-top:solid 1px rgba(187, 187, 187, 100);}
  .liry .img1{width:15%;display: inline-block;float: left;margin:0 4% 4% 4%;}
  .liry .img2{width:8%;display: inline-block;float: right;margin:4% 4% 0 0;}
  .tytuihuo{padding-bottom:70px;}
  .liry .ggg{float: left;}
  .liry .ggg p{color: rgba(16, 16, 16, 100);
font-size: 12px;margin-bottom:3%;
font-family: 方正准圆-标准;}
 .tytuihuo .p{color: rgba(16, 16, 16, 100);display: block;
font-size: 13px;display: block;line-height:23px;margin:3% 4%;
font-family: 方正准圆-标准;}
.tytuihuo .p8{font-size:12px;color: rgba(217, 0, 0, 100);margin-top:0%;margin-bottom:0%;font-size:15px;font-family: 方正准圆-标准}
.tytuihuo .ps{color: rgba(189, 189, 189, 100);font-size:12px;line-height:20px;margin-bottom:10%;}
</style>
